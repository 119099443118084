"use strict";


const baseURL = "https://internalssecommerce-60323-ruby.60323.dev.ap-southeast-1.aws.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
