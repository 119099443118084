// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import InteractiveFaqs from "../../blocks/InteractiveFaqs/src/InteractiveFaqs";
import Reviews from "../../blocks/Reviews/src/Reviews";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Sorting from "../../blocks/sorting/src/Sorting";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import InventoryManagement from "../../blocks/InventoryManagement/src/InventoryManagement";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Sms from "../../blocks/Sms/src/Sms";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import UploadMedia from "../../blocks/UploadMedia/src/UploadMedia";
import Share from "../../blocks/Share/src/Share";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import Payments from "../../blocks/Payments/src/Payments";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import Wishlist from "../../blocks/Wishlist/src/Wishlist";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ShippingChargeCalculator from "../../blocks/ShippingChargeCalculator/src/ShippingChargeCalculator";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import LiveChat from "../../blocks/LiveChat/src/LiveChat";
import AddressManagement from "../../blocks/AddressManagement/src/AddressManagement";



const routeMap = {
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
InteractiveFaqs:{
 component:InteractiveFaqs,
path:"/InteractiveFaqs"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Sms:{
 component:Sms,
path:"/Sms"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
UploadMedia:{
 component:UploadMedia,
path:"/UploadMedia"},
Share:{
 component:Share,
path:"/Share"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
Payments:{
 component:Payments,
path:"/Payments"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Wishlist:{
 component:Wishlist,
path:"/Wishlist"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ShippingChargeCalculator:{
 component:ShippingChargeCalculator,
path:"/ShippingChargeCalculator"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
LiveChat:{
 component:LiveChat,
path:"/LiveChat"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},

  Home: {
component:Categoriessubcategories,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
